<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-card title="지도 미리보기" class="cardClassDetailForm" :noMarginPadding="true" :height="setImgHeight"> 
          <template slot="card-detail">
            <div class="col-12">
              <q-img :src="mapSrc" fit="scale-down"
                :contain="true"
                :ratio="1">
                <!-- style="min-width: 320px; max-width: 700px" -->
                <template v-slot:loading>
                  <q-spinner-gears color="white" />
                </template>
              </q-img>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <c-table
          ref="table"
          title="배치도면 목록"
          tableId="riskHazrd01"
          :columns="grid.columns"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          selection="single"
          rowKey="sopMapId"
          @getTableHeight="(height) => { this.imgHeight = height }"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
              <c-btn v-if="editable" label="LBLSELECT" icon="check" @btnClicked="select" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props }">
            <template>
              <q-btn 
                round unelevated 
                size="6px"
                color="amber" 
                icon="search"
                @click="rowClick(props.row, props.pageIndex)" />
            </template>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'work-map-pop',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          plantCd: '',
        }
      },
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        haveMap: 'show',
      },
      file: null,
      imgHeight: '',
      mapSrc: require('@/assets/images/no-image.png'),
      grid: {
        columns: [
          {
            name: 'src',
            field: 'src',
            label: '미리보기',
            align: 'center',
            style: 'width:50px',
            type: 'custom',
            sortable: false
          },
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'mapName',
            field: 'mapName',
            label: '배치도면명',
            align: 'left',
            sortable: true,
            style: 'width:300px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setImgHeight() {
      let returnText = '';
      returnText = this.imgHeight ? (Number(this.imgHeight.replace('px', '')) - 32) + 'px' : '500px'
      return returnText;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.swp.map.list.url
      // code setting
      // list setting
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd
      }
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    rowClick(row) {
      if (row) {
        this.$comm.previewImage({
          sysAttachFileId: row.sysAttachFileId,
          contentType: row.contentType,
        }).then(_result => {
          this.mapSrc = _result;
        });
      } else {
        this.mapSrc = require('@/assets/images/no-image.png')
      }
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '배치도면을 선택하세요.', // 배치도면을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
<style lang="sass">
.work-map-chip
  .q-chip__content
    display: none

</style>